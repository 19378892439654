<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('ddgl')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item label="交易号">
                                    <el-input v-model="searchform.claveRastreo" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="clave de rastreo">
                                    <el-input v-model="searchform.papeleta" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="订单号">
                                    <el-input v-model="searchform.mchOrderNo" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="clabe">
                                    <el-input v-model="searchform.clabe" style="width: 188px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item v-if="user.user.roles[0].id == 1" label="商户id">
                                    <el-input v-model="searchform.mchId" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="渠道">
                                    <el-select v-model="searchform.channel" placeholder="请选择">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option label="oxxo_card" value="2"></el-option>
                                        <el-option label="cardPay" value="3"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('time')">
                                    <el-date-picker
                                    v-model="value1"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="createTime1" label="创建时间" :width="160">
                            <!-- <template slot-scope="scope">
                                {{scope.row.createTime|changedate}}
                            </template> -->
                        </el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1" prop="orgId" label="商户id" width="118"></el-table-column>
                        <el-table-column prop="claveRastreo" label="交易号"></el-table-column>
                        <el-table-column prop="mchOrderNo" label="订单号"></el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1" prop="sourceAmount" label="起源金额" width="88"></el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1" prop="cost" label="服务费" width="88"></el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1" prop="balance" label="商户入账" width="88"></el-table-column>
                        <!-- <el-table-column prop="cuentaBeneficiario" label="付款参考"></el-table-column> -->
                        <el-table-column prop="hogares" label="收款公户账号"></el-table-column>
                        <el-table-column prop="channelName" label="渠道" width="88"></el-table-column>
                        <el-table-column prop="status" label="状态" width="88">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status==1" type="primary" size="mini" title="待审核">待审核</el-tag>
                                <el-tag v-if="scope.row.status==2" type="success" size="mini" title="支付成功">支付成功</el-tag>
                                <el-tag v-if="scope.row.status==3" type="success" size="mini" title="支付拒绝">支付拒绝</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="monto" label="收支金额" width="88"></el-table-column>
                        <el-table-column prop="papeleta1" label="clave de rastreo" width="128"></el-table-column>

                        <!-- <el-table-column prop="describe" label="描述"></el-table-column> -->
                        <el-table-column prop="jobName" :label="$t('operator')" width="150">
                            <template slot-scope="scope">
                                <el-tooltip style="margin-left: 10px;" v-if="scope.row.status == 1" class="item" effect="dark" content="还款审核">
                                     <el-button size="small" @click="authPay(scope.row)" type="primary" icon="el-icon-check" circle plain></el-button>
                                </el-tooltip>
                                <el-tooltip style="margin-left: 10px;" v-if="scope.row.status == 1 && scope.row.papeleta1 != null && scope.row.channelName == 'OXXO'" class="item" effect="dark" content="查看凭证">
                                     <el-button size="small" @click="queryPap(scope.row)" type="primary" icon="el-icon-zoom-in" circle plain></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
        <pop-up title="还款审核" :isshow="showadd" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="回调金额(比索 MXN):">
                        <el-input-number v-model="form.money" :min="1"/>
                    </el-form-item> 
                    <el-radio-group v-model="form.soluci" style="margin-top: 10px; margin-left: 18px;">
                        <el-radio label="2">支付成功</el-radio>
                        <el-radio label="3">支付拒绝</el-radio>
                    </el-radio-group>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="还款凭证" :isshow="showadd1" :haveconfirm="true" @close="getclose1" @confirm="getconfirm1">
            <div slot="box">
                还款金额：<el-input v-model="imgurl" style="width: 150px;"/><br/><br/>
                确认时间：<el-input v-model="succTime" style="width: 180px;"/><br/><br/>
                还款凭证：<el-image :src="imgurl"></el-image>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    orderId: null,
                    soluci: null,
                    money: null,
                },
                loading: false,
                showadd: false,
                showadd1: false,
                list: [],
                searchform:{},
                total: 1,
                pageNo: 1,
                pageSize: 10,
                imgurl: null,
                succTime: null,
            }
        },
        created() {
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
        },
        methods: {
            queryPap(row) {
                this.showadd1 = true
                this.imgurl = row.papeleta
                this.succTime = row.succTime
            },
            authPay(row) {
                this.showadd = true
                this.form = {
                    orderId: row.id,
                    soluci: null,
                    money: row.sourceAmount,
                }
            },
            getList() {
                this.loading = true
                _api.get(apiurl.repayment, {
                    page: this.pageNo,
                    size: this.pageSize,
                    orderNo: this.searchform.claveRastreo,
                    createTime: this.value1,
                    channel: this.searchform.channel,
                    mchId: this.searchform.mchId,
                    papeleta: this.searchform.papeleta,
                    clabe: this.searchform.clabe,
                    mchOrderNo: this.searchform.mchOrderNo
                }).then(res => {
                    this.loading = false
                    this.list = res.data.data
                    this.total = res.data.totalCount
                })
            },
            getconfirm() {
                _api.put(apiurl.authPay, this.form).then(res => {
                    this.$notify.success({
                        title: this.$t('success'),
                        message: '审核成功'
                    })
                    this.getList()
                    this.form = {
                        orderId: null,
                        soluci: null,
                        money: null,
                    }
                    this.showadd = false
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    })
                })
            },
            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },
            getconfirm1(){
                this.showadd1 = false
            },
            getclose1() {
                this.showadd1 = false
                this.getList()
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>